<template>
  <div id="nav">
    <div class="btn" :class="[isCn ? 'cn' : 'en']" @click="lang">
      <span>{{ isCn ? "中" : "英" }}</span>
    </div>
    <div class="location" />
    <div class="nav_frame">
      <div
        :class="[nav === 5 ? 'nav_btn active' : 'nav_btn']"
        @click="contact_us()"
      >
        联系我们
      </div>
      <div
        :class="[nav === 4 ? 'nav_btn active' : 'nav_btn']"
        @click="go_product()"
      >
        产品详情
      </div>
      <div
        :class="[nav === 3 ? 'nav_btn active' : 'nav_btn']"
        @click="go_news()"
      >
        新闻动态
      </div>
      <div
        :class="[nav === 2 ? 'nav_btn active' : 'nav_btn']"
        @click="go_about_us()"
      >
        关于我们
      </div>
      <div
        :class="[nav === 1 ? 'nav_btn active' : 'nav_btn']"
        @click="go_index()"
      >
        首页
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "Header",
  props: { nav: Number },
  data() {
    return {
      isCn: true,
    };
  },
  setup() {
    const router = useRouter();
    const jump_page = (page_name, value) => {
      router.push({
        name: page_name,
        params: {
          value: JSON.stringify(value),
        },
      });
    };
    return {
      jump_page,
    };
  },
  methods: {
    lang() {
      this.isCn = !this.isCn;
    },
    go_about_us() {
      if (this.nav !== 2) {
        this.jump_page("About");
      }
    },
    go_index() {
      if (this.nav !== 1) {
        this.jump_page("Home");
      }
    },
    contact_us() {
      if (this.nav !== 5) {
        this.jump_page("ContactUs");
      }
    },
    go_product() {
      if (this.nav !== 4) {
        this.jump_page("Product");
      }
    },
    go_news() {
      if (this.nav !== 3) {
        this.jump_page("News");
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#nav {
  width: 100%;
  height: 130px;
  background: #ffffff url("../assets/logo.png") 8% center no-repeat;
  background-size: 63px auto;
  z-index: 99;
  .btn {
    width: 20px;
    height: 20px;
    margin: 66px 5% 0 22px;
    border-radius: 3px;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px #323044 solid;
    float: right;
    span {
      display: block;
    }
    &.cn {
      font-size: 14px;
      color: #323044;
      line-height: 20px;
      text-align: center;
    }
    &.en {
      font-size: 14px;
      color: #323044;
      line-height: 20px;
      text-align: center;
    }
  }
  .location {
    width: 18px;
    height: 20px;
    margin-top: 66px;
    margin-left: 106px;
    background: url("../assets/location.png") center center no-repeat;
    background-size: contain;
    float: right;
  }
  .nav_frame {
    height: 130px;
    float: right;
    display: block;
    .nav_btn {
      height: 42px;
      margin: 53px 16px 0;
      font-size: 16px;
      color: #323044;
      line-height: 42px;
      float: right;
      cursor: pointer;
      &.active {
        border-bottom: 5px solid #c31920;
      }
    }
    &:after {
      content: ".";
      display: block;
      height: 0;
      clear: both;
      visibility: hidden;
    }
  }
}
</style>
